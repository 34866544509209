import { useFormikContext } from 'formik';

export const useFilterCondition = (name: string, filterCondition?: Array<Record<string, any>>) => {
  const formik = useFormikContext<Record<string, any>>();
  let filterData = {};
  let isDisabled = false;
  filterCondition = filterCondition?.filter((e) => !e.isDependentKey);
  if (filterCondition && filterCondition.length) {
    for (let i = 0; i < filterCondition.length; i++) {
      const { control, filterColumn } = filterCondition[i];
      filterData = {
        [filterColumn]: formik.values[control.name],
      };

      if (!formik.values[control.name]) {
        isDisabled = true;
        formik.values[name] = '';
      }
    }
  }
  return { filterData, isDisabled };
};
